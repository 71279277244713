// libraries
import { useMemo, ReactElement, lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Theme } from '@emotion/react'
import _ from 'lodash'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
  useAuthStateValue,
  StaticResourceProvider,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding } from 'hooks'
import { lazyWithRetry } from 'routers/utils'

// components
import { AppError, Loading } from 'components/common'
import HotToaster from 'components/common/Toast/HotToaster'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import variables from 'SUExplorer.module.scss'

const MissionControlStudio = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlStudio'))
)

const MissionControlMethaneSolution = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlMethaneSolution'))
)

const SUExplorer = () => {
  const { appName } = useAuthStateValue()

  const App =
    appName === APP_NAMES.methane
      ? MissionControlMethaneSolution
      : MissionControlStudio

  return (
    <Suspense fallback={<Loading />}>
      <App />
      <HotToaster />
    </Suspense>
  )
}

const ThemedSuExplorer = (): ReactElement => {
  const { title, colour = {} } = useBranding()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as Theme
  }, [colour])
  return (
    <ThemeProvider theme={adjustedColour}>
      <AuthenticationProvider>
        <>
          <Helmet>
            <title>{title || 'SensorUp Mission Control'}</title>
          </Helmet>
          <SUExplorer />
        </>
      </AuthenticationProvider>
      <style>
        {`:root {
              --primary-100: ${adjustedColour['primary-100']};
              --primary-500: ${adjustedColour['primary-500']};
              --secondary-light-500: ${adjustedColour['secondary-light-500']};
              --secondary-light-600: ${adjustedColour['secondary-light-600']};
            }`}
      </style>
    </ThemeProvider>
  )
}

const SUExplorerContainer = (): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <AppError message={error.toString()} />}
    >
      <RecoilRoot>
        <StaticResourceProvider>
          <ConfigProvider>
            <StateProvider>
              <AbilityProvider>
                <ThemedSuExplorer />
              </AbilityProvider>
            </StateProvider>
          </ConfigProvider>
        </StaticResourceProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
