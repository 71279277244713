import _ from 'lodash'

import { APP_NAMES } from 'constants/common'

type Tab = { label: string; value: string; route: string }
type Tabs = Tab[]

export const getTabRoute = (tabs: Tabs, tab: string): string => {
  const newTab = (_.find(tabs, { value: tab }) ?? _.first(tabs)) as Tab
  return _.get(newTab, 'route')
}

export const getTabView = (tabs: Tabs, route?: string): string => {
  const newTab = (_.find(tabs, { route }) ?? _.first(tabs)) as Tab
  return _.get(newTab, 'value')
}

export const makeMethaneAppEntityName = (entityName: string): string =>
  `${APP_NAMES.methane}_${entityName}`

/**
 * For some tables, we want to show the 'whole content' loader only on the first request.
 * On filtering and sorting, we want to show a loader inside the table so the content stays.
 *  */
export const getListLoadingProp = ({
  isFirstRequestDone,
  loading,
}: {
  isFirstRequestDone?: boolean
  loading?: boolean
}): { tableLoading?: boolean } | { loading?: boolean } =>
  isFirstRequestDone ? { tableLoading: loading } : { loading }

export const roundToDecimalPlaces = (
  value: number | string,
  decimalPlaces = 2
): number => Number(parseFloat(value).toFixed(decimalPlaces))

export const getTableHeaderWithUnit = (header: string, unit: string): string =>
  `${header} (${unit})`
