import { useMemo } from 'react'
import _ from 'lodash'

import { useConfigStateValue } from 'contexts'

import type { Payload } from 'types/common'

export const initialEnabledFeatureFlagState = {
  rulesEngine: true,
  tileLayer: false,
  issue: true,
  site: false,
  asset: false,
  workflowGeofenceAncillaryData: false,
  dynamicScheduling: false,
  jsonForm: true,
  createUser: true,
  ldarSimFileUploader: false,
  jsonFormListBuilder: false,
}

const useFeatureFlag = (): Payload<boolean> => {
  const { featureFlags } = useConfigStateValue() || {}

  const result = useMemo(
    () => _.defaults({}, featureFlags, initialEnabledFeatureFlagState),
    [featureFlags]
  )

  return result
}

export default useFeatureFlag
