import keymirror from 'keymirror'
import _ from 'lodash'

import SecurityAndPolicy from 'components/settings/SecurityAndPolicy'
import UserProfile from 'components/settings/UserProfile'
import Versions from 'components/settings/Versions'
import FormGallery from 'routers/pages/FormGallery'

export const NOTIFICATION_CLOSE_TIME = 5000

export const FEATURES = {
  WORKFLOW_GEOFENCE_ANCILLARY_DATA: 'workflowGeofenceAncillaryData',
  WORKFLOW: 'rulesEngine',
  TILE_LAYER: 'tileLayer',
  ANCILLARY_DATA: 'assetsManagement',
  ISSUE: 'issue',
  SITE: 'site',
  ASSET: 'asset',
  DYNAMIC_SCHEDULING: 'dynamicScheduling',
  CREATE_USER: 'createUser',
  JSON_FORM: 'jsonForm',
  JSON_FORM_LIST_BUILDER: 'jsonFormListBuilder',
  LDAR_SIM_FILE_UPLOADER: 'ldarSimFileUploader',
}

export const MODAL_SIZES = keymirror({
  extraSmall: null,
  small: null,
  regular: null,
  large: null,
})

export const MODAL_TYPES = keymirror({
  warning: null,
  info: null,
  danger: null,
})

export const SETTINGS_TYPES = keymirror({
  userProfile: null,
  securityAndPrivacy: null,
  versions: null,
  formDesigner: null,
})

export const SETTINGS_MENU = [
  {
    label: 'MY SETTINGS',
    group: [
      {
        label: 'My profile',
        value: SETTINGS_TYPES.userProfile,
        icon: 'FaRegUserCircle',
        component: UserProfile,
      },
      {
        label: 'Security and Privacy',
        value: SETTINGS_TYPES.securityAndPrivacy,
        icon: 'MdSecurity',
        component: SecurityAndPolicy,
      },
      {
        label: 'Versions',
        value: SETTINGS_TYPES.versions,
        component: Versions,
      },
    ],
  },
  {
    label: 'APP CONFIGURATION',
    group: [
      {
        label: 'Form designer',
        value: SETTINGS_TYPES.formDesigner,
        icon: 'FormBuilderIcon',
        component: FormGallery,
        isExpanded: true,
        enabled: FEATURES.JSON_FORM,
        sidebarRouteEnabled: true,
      },
    ],
  },
]

export const SETTINGS_MENU_WITHOUT_GROUP = _.flatMap(SETTINGS_MENU, 'group')

export const SETTINGS_MENU_KEY_BY_VALUE = _.keyBy(
  SETTINGS_MENU_WITHOUT_GROUP,
  'value'
)

export const TOOLTIP_PLACEMENT = keymirror({
  left: null,
  right: null,
  top: null,
  bottom: null,
  topLeft: null,
  topRight: null,
  bottomLeft: null,
  bottomRight: null,
  rightTop: null,
  rightBottom: null,
  leftTop: null,
  leftBottom: null,
})

export const USER_PREFERENCES = keymirror({
  sidebarRoutes: null,
  filters: null,
  favoriteMaps: null,
  favoriteFormLists: null,
  enableTimezone: null,
  galleryOptions: null,
})
